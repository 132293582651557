import React from "react"

import Layout from "../components/layout"
import SearchTitle from "../components/common/SearchTitle"
import BlogPostsListWithSeo from "../components/common/BlogPostsListWithSeo"

const TagTemplate = (props) => {
  const { pageContext, path } = props

  return (
    <Layout>
      <SearchTitle>
        <h1>Posts tagged with #{pageContext.tagName}</h1>
      </SearchTitle>
      <BlogPostsListWithSeo
        initialPosts={pageContext.posts}
        totalCount={pageContext.totals}
        path={path}
        metaKeywords={pageContext.metatags}
        metaTitle={`Posts tagged with #${pageContext.tagName}`}
        metaDescription={`If I'm doing it right, on this page you should see all my articles related to ${pageContext.tagName}.`}
      />
    </Layout>
  )
}

export default TagTemplate